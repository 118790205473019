import React from "react";
import Workshops from "../../components/LMS/Workshops";

function Workshop() {
  return (
    <div>
      <Workshops />
    </div>
  );
}

export default Workshop;
